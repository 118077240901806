.toolbarButton {
    background-color: #ffffff;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-image: url(assets/filter.svg);
    mask-image: url(assets/filter.svg);
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
}

.active > .toolbarButton {
    background-color: #00bfff;
}

.hoverEffect:hover > .toolbarButton {
    background-color: #00bfff;

}