body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.esri-view-surface--inset-outline:focus::after {
    outline: none !important;
}

.esri-component.esri-popup {
    display: none;
}

.esri-ui-top-left {
    margin-top: 45px;
    margin-left: 8px;
}

.esri-scale-range-slider__scale-menu-container {
    display: none;
}

.disabled { 
    pointer-events: none;
    opacity: 0.5;
}

#toolbar-sectionTool,
#toolbar-propertiesTool {
    display: none !important;
}

th.jsonColumn {
    border-bottom: 1px solid black;
}

.jsonOdd {
    background-color: #eee;
}

.forge-spinner > svg > polygon:first-of-type {
    fill: transparent
}

.tips-editor {
    border: 1px solid #eee;
    padding: 0 16px;
    border-radius: 2px;
    min-height: 400px;
    max-height: 400px;
    min-width: 700px;
    max-width: 700px;
}