.root {
    display: flex;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    max-height: calc(100% + 2px);
    max-width: calc(100% + 2px);
    margin: -1px;
}

.even,
.odd {
    flex: 1;
}

.horizontal,
.mixed,
.vertical {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    height: auto;
}

.vertical,
.mixed {
    flex-direction: column;
}

.pane {
    display: flex;
    position: relative;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    background-color: white;
    overflow: hidden;
    height: auto;
}

.even .horizontal .pane,
.even .vertical .pane,
.odd .mixed .pane {
    flex: 0 0 50%;
}

.even .horizontal .pane:not(:only-child),
.odd .mixed .pane {
    max-width: 50%;
}

.even .vertical .pane {
    max-width: 100%;
}

.odd .horizontal .pane,
.odd .vertical .pane {
    flex: calc(100% / 3);
}

.odd .horizontal .pane:not(:only-child) {
    max-width: calc(100% / 3);
}

.odd .vertical .pane {
    max-width: 100%;
}

.mixed .pane:first-of-type {
    flex: 0 0 100%;
}

.mixed .pane {
    min-width: 50%;
    max-width: 50%;
}

.horizontal:has(> :last-child:nth-child(4)) .pane {
    height: calc(50vh - 31px);
    max-height: calc(50vh - 31px);
}

.border {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    box-sizing: border-box;
    pointer-events: none;
}

.border.active {
    border: 4px solid #8dd9c8;
}

.pane:hover > .border:not(.active) {
    border: 4px solid #499c85;
}

.message {
    display: flex;
    margin: auto;
    padding: 0 25px;
    text-align: center;
}

.feedback {
    position: absolute;
    bottom: 24px;
    left: 16px;
    z-index: 10000;
}
